<template>
  <div class="p-4 pb-8 bg-white rounded-lg">
    <div class="mb-7 mx-10 mt-6 d-flex align-center justify-space-between">
      <datatable-detail class="col-6" :table_properties="tableProperties" />

      <div class="col-6 justify-content-end px-4">
        <button
          class="btn btn--export-filter float-end"
          @click.prevent="submitRole"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-cloud-upload</v-icon>
          </span>
          Submit
        </button>
      </div>
    </div>

    <div
      class="custom-container-height w-100 mx-0 pb-3 px-0 px-sm-2 px-md-8 d-flex flex-wrap poppins"
    >
      <NestedDataTable
        tableName="roles"
        editType="role"
        :handleGetFunction="handleRoleSelect"
        :handleCreateRole="handleCreateRole"
      />
      <NestedDataTable
        tableName="pages"
        editType="page"
        :handleGetFunction="handlePageSelect"
      />
      <NestedDataTable
        tableName="actions"
        editType="action"
        :handleGetFunction="handleActionSelect"
      />
      <NestedDataTable tableName="columns" editType="column" />
    </div>
    <CreateRole ref="createRole" />
  </div>
</template>

<script>
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import rolesNestedDataMixin from "@/own/mixins/rolesNestedDataMixin";
import NestedDataTable from "@/own/components/roles/NestedDatatable.vue";
import CreateRole from "@/own/components/roles/CreateRole.vue";

export default {
  name: "RolesExample",
  components: {
    NestedDataTable,
    CreateRole,
    DatatableDetail,
  },
  mixins: [rolesNestedDataMixin],
};
</script>

<style scoped>
.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 327px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 300px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 285px) !important;
  }
}

@media (max-width: 768px) {
  .custom-container-height {
    height: calc(100vh - 275px) !important;
  }
}
</style>
